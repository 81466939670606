@use "sass:meta" as ---icwi85i8eb;.ReactModal__Content {
  transform: translateY(rem-calc(50));
  transition: transform $duration $easing;
}

.ReactModal__Content--after-open {
  transform: none;
}

.ReactModal__Content--before-close {
  transform: translateY(rem-calc(50));
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity $duration $easing;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

;@include ---icwi85i8eb.load-css("sass-embedded-legacy-load-done:75197");