@use "sass:meta" as ---icwi85i8eb;@import "utils/vars";

%button-reset {
  padding: 0;
  font: inherit;
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
}

%text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

%full-height {
  height: calc(100vh - var(#{$header-height}));
}

%smooth-scroll {
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

%image-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

%box-shadow {
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.05);
}

%no-user-select {
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

%default-user-select {
  -khtml-user-select: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  -o-user-select: text;
  user-select: text;
}

;@include ---icwi85i8eb.load-css("sass-embedded-legacy-load-done:100570");